import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import useToken from "../hooks/useToken";
import axios from "axios";

interface QueriedUser {
  id: string;
  nickName: string;
  headImg: string;
  jwt: string;
}
type UserContextType = {
  user?: QueriedUser;
  jwt: () => string | null;
};

interface UserInfo {
  token: string;
  name: string;
  no: string;
  headImg: string;
}

interface AuthContextValue {
  user: UserInfo | null;
  setUser: (a: UserInfo | null) => void;
}

const LS_JWT_KEY = "jwt";
const AuthContextReact = createContext<AuthContextValue>({
  user: null,
  setUser: (a: UserInfo | null) => {},
});

export const useAuth = () => {
  return useContext(AuthContextReact);
};

export default function AuthContext({
  children,
}: {
  children: React.ReactNode;
}) {
  // const { getToken } = useToken();
  // const userToken = getToken();
  const u = localStorage.getItem("user");
  console.log("u", u);
  const [user, setUser_] = useState<UserInfo | null>(u ? JSON.parse(u) : null);

  const setUser = (_user: UserInfo | null) => {
    console.log("setUser", _user);
    setUser_(_user);
    if (_user) {
      localStorage.setItem("user", JSON.stringify(_user));
    } else {
      localStorage.removeItem("user");
    }
  };

  useEffect(() => {
    if (user) {
      // axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
      // localStorage.setItem("token", token);
    } else {
      // delete axios.defaults.headers.common["Authorization"];
      // localStorage.removeItem("user");
    }
  }, [user]);

  const contextValue = useMemo((): AuthContextValue => {
    return {
      user,
      setUser,
    };
  }, [user]);

  return (
    <>
      <AuthContextReact.Provider value={contextValue}>
        {children}
      </AuthContextReact.Provider>
    </>
  );
}
