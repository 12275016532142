import { TabBar, TabBarItem } from "antd-mobile/es/components/tab-bar/tab-bar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppOutline,
  MessageOutline,
  MessageFill,
  TeamOutline,
  UserOutline,
} from "antd-mobile-icons";
import React from "react";

const BottomTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const tabs = [
    {
      key: "/",
      title: "首页",
      icon: <AppOutline />,
    },
    {
      key: "/match",
      title: "匹配",
      icon: <TeamOutline />,
      badge: "5",
    },
    {
      key: "/user",
      title: "我的",
      icon: <UserOutline />,
    },
  ];

  return (
    <>
      <TabBar activeKey={pathname} onChange={(value) => navigate(value)}>
        {tabs.map((item) => (
          <TabBarItem key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </>
  );
};

export default BottomTab;
