import React from "react";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import type { ReactNode } from "react";
// import type { RouteObject } from 'react-router-dom';
import {
  Outlet,
  createBrowserRouter,
  // createHashRouter,
} from "react-router-dom";
import BottomTab from "./components/BottomTab";
import AuthContext from "./components/AuthContext";

const entry = [
  {
    path: "/",
    lazy: () => import("./pages/Home"),
  },
  {
    path: "/user",
    lazy: () => import("./pages/user/index"),
  },
  {
    path: "/match",
    lazy: () => import("./pages/match/index"),
  },
];

const pages = [
  {
    path: "/news",
    lazy: () => import("./pages/news"),
  },
  {
    path: "/single",
    lazy: () => import("./pages/singles"),
  },
  {
    path: "/user/resume",
    lazy: () => import("./pages/user/resume"),
  },
  {
    path: "/news/detail",
    lazy: () => import("./pages/news/detail"),
  },
  {
    path: "/user/frq",
    lazy: () => import("./pages/user/frq"),
  },
  {
    path: "/user/contact",
    lazy: () => import("./pages/user/contact"),
  },
  {
    path: "/user/signin",
    lazy: () => import("./pages/user/signin"),
  },
  {
    path: "/user/setting",
    lazy: () => import("./pages/user/setting"),
  },
  {
    path: "/user/about",
    lazy: () => import("./pages/user/About"),
  },
  {
    path: "/user/privacy",
    lazy: () => import("./pages/user/privacy"),
  },
  {
    path: "/user/policy",
    lazy: () => import("./pages/user/policy"),
  },
]; // satisfies [RouteObject, ...RouteObject[]];

const router = createBrowserRouter(
  [
    {
      element: (
        <>
          <AuthContext>
            <div
              style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#121317",
                color: "#fff",
                caretColor: "#fff",
              }}
            >
              <div
                style={{
                  flex: 1,
                }}
              >
                <Outlet></Outlet>
              </div>
              <div
                style={{
                  flex: 0,
                  // borderTop: "solid 1px var(--adm-color-border)",
                }}
              >
                <BottomTab />
              </div>
            </div>
          </AuthContext>
        </>
      ),
      children: entry,
    },
    {
      element: (
        <>
          <AuthContext>
            <div
              style={{
                minHeight: "100vh",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#121317",
                color: "#fff",
              }}
            >
              <Outlet></Outlet>
            </div>
          </AuthContext>
        </>
      ),
      children: pages,
    },
  ],
  {
    future: {
      v7_normalizeFormMethod: true,
    },
  }
);

function App() {
  return (
    <>
      <RouterProvider router={router} />

      {/* <i className="accessible icon" style={{visibility:'visible'}}></i> */}
    </>
  );
}

export default App;
